import React, { useEffect, useState } from "react"
import Img from "gatsby-image"
import "./HomeServices.scss"
import Btn from "../Btn"

import PageTransitionLink from "../PageTransitionLink"
import { motion } from "framer-motion"
import { returnContentfulMediaType } from "../../utils/helpers"

const useDelayedVideoSrcTimer = () => {
  const [shouldLoadDelayedVideo, setshouldLoadDelayedVideo] = useState(false)

  useEffect(() => {
    setTimeout(() => setshouldLoadDelayedVideo(true), 500)
  }, [])

  return shouldLoadDelayedVideo
}

const ServiceMediaComponent = ({ media }) => {
  const mediaType = returnContentfulMediaType(media)

  const shouldLoadDelayedVideo = useDelayedVideoSrcTimer()

  if (mediaType === "image") {
    return (
      <Img
        fluid={media.localFile.childImageSharp.fluid}
        style={{ position: "absolute" }}
      />
    )
  }
  if (mediaType === "video") {
    const videoUrl = media.localFile.publicURL
    const contentfulVideoUrl = media.file.url
    return (
      <video
        // className="home-hero__video"
        // src={shouldLoadDelayedVideo ? videoUrl : ""}
        src={contentfulVideoUrl}
        autoPlay
        muted
        loop
        style={{ position: "absolute" }}
      ></video>
    )
  }
  return <div>Media missing</div>
}

const HomeServiceItem = ({ service }) => {
  console.log({ service })
  const serviceMedia = service.media

  return (
    <motion.div className="home-service-item shadow-lg">
      <h4 className="home-service-item__title">{service.title}</h4>
      <div className="home-service-item__content">
        <div className="home-service-item__text-content">
          <p>{service.description}</p>
          <PageTransitionLink to={service.json.to}>
            <Btn size="lg" fluid={true}>
              More Info
            </Btn>
          </PageTransitionLink>
        </div>
        <div className="home-service-item__image">
          <ServiceMediaComponent media={serviceMedia} />
        </div>
      </div>
    </motion.div>
  )
}

const HomeServices = ({ homepageData }) => {
  const { homeServices } = homepageData
  return (
    <section className="home-services container">
      <h2 className="home-services__title">Services we provide</h2>
      <p className="home-services__description">
        Our bread and butter is Architectural 3D renders and Virtual Reality
        Walk-throughs, however <br className="hidden md:block" />
        we also work with 3D product placement, video advertising and print
        advertising of all sizes.
      </p>

      <div className="home-services__item-container">
        {homeServices.map(service => (
          <HomeServiceItem service={service} key={service.title} />
        ))}
      </div>

      <p className="centered-cta-line">
        For a no obligation quote on your new project -{" "}
        <PageTransitionLink to="/contact/" className="font-semibold">
          Get in touch!
        </PageTransitionLink>{" "}
      </p>
    </section>
  )
}
export default HomeServices
