import React, { useEffect, useState } from "react"
import { motion } from "framer-motion"

import "./HomePortfolioSlider.scss"
import Image from "../../assets/images/images"
import Img from "gatsby-image"
import { useStaticQuery } from "gatsby"

const SliderItem = ({ sectionHeight, item }) => {
  const slideHeight = sectionHeight
  const slideWidth = sectionHeight * 1.333
  const slideImageFluid = item.localFile.childImageSharp.fluid
  const contentfulSlideImageFluid = item.fluid

  return (
    <motion.div
      className="home-portfolio-slider__item"
      animate={{ opacity: 0.25 }}
      //   whileHover={{ opacity: 0.5 }}
      style={{ width: slideWidth, height: slideHeight }}
    >
      <Img
        fluid={contentfulSlideImageFluid}
        className="h-full"
        imgStyle={{ objectFit: "cover" }}
      />
    </motion.div>
  )
}

const HomePortfolioSlider = ({ sectionHeight }) => {
  const homePortfolioItems = useHomePortfolioItems()
  console.log({ homePortfolioItems })
  return (
    <div className="home-portfolio-slider__container">
      <motion.div className="home-portfolio-slider__items-container">
        {homePortfolioItems.map((item, index) => (
          <SliderItem sectionHeight={sectionHeight} key={index} item={item} />
        ))}
        {homePortfolioItems.map((item, index) => (
          <SliderItem
            sectionHeight={sectionHeight}
            key={index + homePortfolioItems.length}
            item={item}
          />
        ))}
      </motion.div>
    </div>
  )
}

export default HomePortfolioSlider

const useHomePortfolioItems = () => {
  const data = useStaticQuery(
    graphql`
      {
        contentfulHomePage {
          homePortfolioCarousel {
            localFile {
              childImageSharp {
                fluid(maxHeight: 520, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            fluid(maxWidth: 800, quality: 75) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    `
  )
  return data.contentfulHomePage.homePortfolioCarousel
}

const use360VideoQuery = () => {}
