import React from "react"
import { useMeasure } from "react-use"

import Btn from "../../components/Btn"
import PageTransitionLink from "../PageTransitionLink"
import "./HomePortfolio.scss"
import HomePortfolioSlider from "./HomePortfolioSlider"

const HomePortfolio = () => {
  const [ref, { height: sectionHeight }] = useMeasure()
  return (
    <section
      className="home-portfolio shadow-lg"
      style={{ backgroundColor: "#363636" }}
      ref={ref}
    >
      <div className="container relative">
        <div className="home-portfolio__text-content">
          <h2 className="home-portfolio__title">Portfolio</h2>
          <p className="home-portfolio__description">
            Check out some of the projects we've previously worked on.
          </p>

          <PageTransitionLink to="/portfolio/">
            <Btn>View Portolio</Btn>
          </PageTransitionLink>
        </div>
        <HomePortfolioSlider sectionHeight={sectionHeight} />
      </div>
    </section>
  )
}

export default HomePortfolio
