import React from "react"
import SEO from "../seo"

import HomeHero from "./HomeHero"
import HomeServices from "./HomeServices"
import HomeAbout from "./HomeAbout"

import "./index.scss"
import HomePortfolio from "./HomePortfolio"
import HomeContact from "./HomeContact"
import { useStaticQuery } from "gatsby"

const HomePage = () => {
  const homepageData = useHomepageQuery()

  return (
    <div>
      <SEO
        title="Home"
        description="At Max VR, we create photo-realistic architectural renders and immersive 3D experiences that bring your plans to life. We are based in Christchurch, New Zealand."
      />

      <HomeHero homepageData={homepageData} />
      <HomeServices homepageData={homepageData} />
      <HomePortfolio />
      <HomeAbout />
      {/* <HomeContact /> */}
    </div>
  )
}

export default HomePage

const useHomepageQuery = () => {
  const data = useStaticQuery(
    graphql`
      query {
        contentfulHomePage {
          homeHeading
          homeDescription
          heroMedia {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              publicURL
            }
            file {
              contentType
              url
            }
          }
          homeServices {
            title
            description
            json {
              to
            }
            media {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              file {
                contentType
                url
              }
              localFile {
                publicURL
              }
            }
          }
        }
      }
    `
  )
  return data.contentfulHomePage
}
