import React from "react"
import NZmapSVG from "../../assets/images/New_Zealand_Simplified.svg"
import "./HomeAbout.scss"

const HomeAbout = () => {
  return (
    <section className="home-about">
      <div className="container">
        <div className="home-about__text-content">
          <h2 className="home-about__title">About us</h2>
          <p className="home-about__description">
            Max VR is based in Christchurch, New Zealand and was established in
            September 2020. With a background in Architectural Design, our team
            has the knowledge and eye for detail to create beautiful, accurate
            3D models that perfectly represent your new project! <br />
            <br />
            In our experience, rendering services can be costly and the result
            isn’t always accurate. Max VR was founded in the aim to change the
            rendering market by providing the highest quality service,
            continuous communication, accuracy and great pricing!
          </p>
        </div>
        <div className="home-about__svg-container">
          <NZmapSVG fill="white" />
        </div>
      </div>
    </section>
  )
}

export default HomeAbout
